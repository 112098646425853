
export const getMetafields = (data) => {
  if(!data.metafields)
    return null;

  return data.metafields.length > 0 ? data.metafields.reduce((acc,curr) => {
    acc[curr.key] = curr;

    return acc;
  },{}) : null;
}
