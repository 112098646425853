import React, { useState } from 'react';
import styled from "styled-components";
import { LazyBlur, LazyBackgroundBlur } from "@manualengineering/react-lazyblur"; 
import ImageBlock from '../ImageBlock/ImageBlock';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
`

const TopWrapper = styled.div`
  display: flex;
  padding: 0 0 10px;
  justify-content: space-between;
`

const BottomWrapper = styled.div`
display: flex;
box-sizing: border-box;
background: #F9F9F9;
border-radius: 10px;
position: relative;
flex-direction: column;
overflow: hidden;
-webkit-mask-image: -webkit-radial-gradient(white, black);
`

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: opacity .2s ease;
  box-sizing: border-box;
  padding: 10px 0 0;

  ${media.large`
    opacity: ${props => props.opacity ? '1' : '0'};
  `}
`

const HoverImage = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1;
opacity: ${props => props.opacity};
transition: opacity .2s ease;
`

const ImageContainer = styled.div`
position: relative;
width: 100%;
`
const Image = styled.img`
display: block;
width: 100%;
`

const Title = styled.h2`
  ${fontstack.default}  
  color: #383B23;
  ${type('m-body')}
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
  word-break: keep-all;
  
  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}

  &:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid #383B23;
    border-radius: 50%;
    margin: 0 10px 0 0;
    background: ${props => props.opacity ? '#383B23' : 'transparent'};
    transition: background .2s ease;
  }
`

const Price = styled.div`
  ${fontstack.default}  
  color: #383B23;
  ${type('m-body')}
  text-transform: uppercase;
  ${media.large`
    ${type('body')}
  `}
`


const Subtitle = styled.h3`
  ${fontstack.default}  
  color: #383B23;
  ${type('m-body')}
  margin: 0;

  ${media.large`
    ${type('body')}
  `}
`

const Description = styled.p`
  ${fontstack.default}  
  color: #383B23;
  ${type('body')}
  margin: 0;
`

const PlpProduct = (props) => {
  const { image, price, hover, title, subtitle, description, placeholder, alt, category, href, productType} = props;
  const [ Opacity, setOpacity ] = useState(0);
  const opacityValue = (productType !== 'icebar') ? 1 : 0.43;

  return (
    <Wrapper>
      <TopWrapper>
        <Title opacity={Opacity}>{title}</Title>
        
      </TopWrapper>
      <BottomWrapper onMouseOver={()=>{ setOpacity(opacityValue) }} onMouseOut={()=>{ setOpacity(0) }}>
        <HoverImage opacity={Opacity}>
          <LazyBackgroundBlur src={`${hover}`} placeholder={`${hover}`} />
        </HoverImage>
        <ImageContainer>
          <ImageBlock src={image} placeholder={placeholder} alt={`Photoshoot shot of ${title} - ${subtitle}`}/>
        </ImageContainer>
      </BottomWrapper>
      <Content opacity={Opacity}>
          <Subtitle>{subtitle}</Subtitle>
          <Price>{price}</Price>
        </Content>
    </Wrapper>
  );
}

export default PlpProduct;