import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Line = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
`

const Item = styled.li`
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  display: flex;
  align-items: center;
  margin: 0 0 5px;

  ${media.large`
    ${type('body')}
    margin: 0;
  `}

  &:after {
    position: relative;
    display: inline-block;
    content: '+';
    margin: 0 5px;
    color: #383B23;
    font-size: 12px;
    top: -2px;
    
    ${media.large`
      content: '';
      width: 25px;
      height: 1px;
      background: #383B23;
    `}

  }

  &:last-of-type:after {
    display: none;
  }
`

const ItemInner = styled.div`
  display: inline-block;
`

const AttributesLine = ({attributes}) => {
  return (
    <Line>
      { attributes && attributes.length > 0 ? attributes.map((attr) => {
        return (
          <Item>
            <ItemInner>
              {attr}
            </ItemInner>
          </Item>
        )
      }) : null }
    </Line>
  )
}

export default AttributesLine;