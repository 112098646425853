import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import AttributesLine from '../AttributesLine/AttributesLine';

const Grid = styled.div`
  ${media.large`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;
    padding: 0 0 10px;
    border-bottom: 1px solid #383B23;
  `}
`

const AttributesWrapper = styled.div`
  grid-column: ${props => props.twoCol ? '7 / span 6' : '4 / span 9'};
  display: ${props => props.desktopAttr ? 'none' : 'block'};

  ${media.large`
    display: block;
  `}
`

const TitleWrapper = styled.div`
  grid-column: ${props => props.twoCol ? '1 / span 6' : '1 / span 3'};
`

const Title = styled.h2`
  ${fontstack.default}
  color: #383B23;
  ${type('m-body')}
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 15px;
  padding: 0 0 5px;
  border-bottom: 1px solid #383B23;

  ${media.large`
    ${type('body')}
    font-weight: 500;
    padding: 0;
    margin: 0;
    border-bottom: none;
  `}
`

const CategoryDivider = (props) => {
  const { title, attributes, twoCol, desktopAttr} = props; 
  return (
    <Grid>
      <TitleWrapper twoCol={twoCol}>
        <Title>{title}</Title>
      </TitleWrapper>
      <AttributesWrapper desktopAttr={desktopAttr} twoCol={twoCol}>
        <AttributesLine attributes={attributes}/>
      </AttributesWrapper>
    </Grid>
  )
}

export default CategoryDivider;