import React from 'react';
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
  margin: 30px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const Copy = styled.h2`
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #383B23;
  grid-column: 1 / span 2;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    grid-column: 2;
  `}
`

const PlpBanner = (props) => {

  const { children } = props;

  return (
    <Wrapper>
      <Copy>
        <HtmlParser>{children}</HtmlParser>
      </Copy>
    </Wrapper>
  )
}

export default PlpBanner;