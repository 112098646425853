import * as React from "react"
import { Link, graphql } from "gatsby"
import { Layout } from "../../../components/Layout/layout"
import styled from 'styled-components';
import { getShopifyImage } from "gatsby-source-shopify"
import { getMetafields } from "../../../utils/getMetafields";
import { SmoothConceptOpen } from "../../../components/SmoothieConcept/SmoothieConcept";
import { GranolaConceptOpen } from "../../../components/SmoothieConcept/GranolaConceptOverlay";
import { Seo } from "../../../components/seo"
import Section from "../../../components/Section/Section";
import PlpBanner from "../../../components/PlpBanner/PlpBanner";
import PlpProduct from "../../../components/PlpProduct/PlpProduct";
import CategoryDivider from "../../../components/CategoryDivider/CategoryDivider";
import Overlay from "../../../components/Overlay/Overlay";
import { fontstack } from '../../../utils/fontstack';
import { type } from '../../../utils/type';
import { media } from '../../../utils/mediaQuery';

const CategoryWrapper = styled.section` 
  position: relative;
`

const PlpBannerSection = styled(Section)`
`


const MainWrapper = styled.div`
  margin: -40px 0 0;

  ${media.large`
    margin: 0;
  `}
`

const CategoryProducts = styled.div`
  position: relative;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin: 65px 0 0;

  ${media.large`
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 90px;
    margin: 50px 0 0;
  `}
`

const OverflowHidden = styled.div`
  overflow: hidden;
`

export default function CollectionIndex({
  data: { collection },
  pageContext: { handle },
}) {
  const collectionData = collection.edges[0].node;
  const title = collectionData.title;
  const seo = collectionData.seo;
  const description = collectionData.descriptionHtml;
  const products = collectionData.products;
  const metafields = getMetafields(collectionData);
  const attributes = metafields && metafields.attributes ? metafields.attributes.value.split(',') : null;
  const hasSmoothieConcept = metafields && metafields.smoothie_concept_overlay ? true : false;
  const hasGranolaConcept = metafields && metafields.granola_concept_overlay ? true : false;

  return (
    <Layout plp={handle}>
      <Seo 
        title={seo.title || title}  
        description={seo.description || description}
      />
      <OverflowHidden>
      <PlpBannerSection none contain>
        <PlpBanner>{description}</PlpBanner>
        { hasSmoothieConcept && <SmoothConceptOpen /> }
        { hasGranolaConcept && <GranolaConceptOpen /> }
      </PlpBannerSection>
      <MainWrapper>
        <Section>
          <CategoryWrapper>
            <CategoryDivider title={title} attributes={attributes} twoCol/>
            <CategoryProducts>
              { products && products.length > 0 ? products.map((product) => {
                const productmeta = getMetafields(product);
                const price = `$${Math.round(product.priceRangeV2.minVariantPrice.amount)}${product.priceRangeV2.minVariantPrice.currencyCode}`;
                const plpImage = productmeta?.plp_image ? productmeta.plp_image.localImage.childImageSharp.gatsbyImageData : null;
                const plpImageSource = plpImage ? plpImage.images.fallback.src : null;
                const plpPlaceholder = plpImage ? plpImage.placeholder.fallback : null;
                const plpImageWidth = plpImage ? plpImage.width : null;
                const plpImageHeight = plpImage ? plpImage.height : null;

                const plpImageHover = productmeta?.plp_hover ? productmeta.plp_hover.localImage.childImageSharp.gatsbyImageData : null;
                const plpImageHoverSource = plpImageHover ? plpImageHover.images.fallback.src : null;
                

                
                
                return <Link to={product.slug}><PlpProduct 
                title={product.title} 
                subtitle={productmeta && productmeta.subtitle ? productmeta.subtitle.value : null} 
                description={productmeta && productmeta.product_hints ? `Hints of: ${productmeta.product_hints.value}` : null}
                price={price} 
                image={plpImageSource} 
                placeholder={{
                  src: plpPlaceholder,
                  width: plpImageWidth,
                  height: plpImageHeight
                }}
                hover={plpImageHoverSource} /></Link>
              }) : null }
            </CategoryProducts>
          </CategoryWrapper>
        </Section>
      </MainWrapper>
      </OverflowHidden>
    </Layout>
  )
}

export const query = graphql`
  query($handle: String!) {
    collection: allShopifyCollection(filter: {handle: {eq: $handle}}) {
      edges {
        node {
          products {
           ...ProductCard
            metafields {
              key
              value
              localImage {
                childImageSharp {
                  gatsbyImageData(width: 1500, placeholder: BLURRED, quality: 80)
                }
              }
            }
          }
          title
          description
          descriptionHtml
          metafields {
            value
            key
          }
          seo {
            description
            title
          }
        }
      }
    }
  }
`
